import { environment } from './../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginationValues } from 'app/shared/models/pagination-values';
import { Chat, ChatNotification } from '../models/chat';

@Injectable({
    providedIn: 'root',
})
export class ChatService {
    constructor(private httpClient: HttpClient) {}

    getProjectChat(
        pageIndex: number,
        pageSize: number,
        id: string
    ): Observable<PaginationValues<Chat>> {
        let _params = new HttpParams();

        _params = _params.append('PageIndex', pageIndex.toString());
        _params = _params.append('PageSize', pageSize.toString());

        return this.httpClient.get<PaginationValues<Chat>>(
            `${environment.apiUrl}Chat/${id}`, {
                params: _params
            }
        );
    }

    getLastSeen(id: string): Observable<any> {
        return this.httpClient.get<any>(
            `${environment.apiUrl}Chat/${id}/lastseen`
        );
    }

    setProjectChat(content: string, projectId: string): Observable<string> {
        return this.httpClient.post<string>(`${environment.apiUrl}Chat`, {
            content: content,
            projectId: projectId,
        });
    }

    getUnreadTotal(): Observable<ChatNotification[]> {
        return this.httpClient.get<ChatNotification[]>(
            `${environment.apiUrl}Chat/unread/total`
        );
    }
}
